import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Row,
  Skeleton,
} from 'antd';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BookOutlined, HomeOutlined, SaveOutlined } from '@ant-design/icons';
import { Config, Puck, usePuck, ActionBar } from '@measured/puck';
import '@measured/puck/puck.css';
import { PuckConfig } from '@/share/PuckConnfig';
import APIService from '@/services/API';
import { useQuery } from 'react-query';
import TypeSelector from '@/components/ui/TypeSelector';
import StatusSelector from '@/components/ui/StatusSelector';
import ImagePicker from '@/components/ui/ImagePicker';

export interface PageEditViewProps {
  edit?: boolean;
}
const PageEditView: React.FC<PageEditViewProps> = ({ edit = false }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const state = useRef<any>({
    slug: {
      en: '',
      es: '',
    },
    name: {
      en: '',
      es: '',
    },
    content: {
      en: '',
      es: '',
    },
    type_id: '',
    status: 'draft',
  });
  const { data: page, isLoading } = useQuery(['page', id], () => APIService.getPage(id), {
    enabled: edit,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    onSuccess(data) {
      state.current = data;
    },
  });
  const nav = useNavigate();

  // Save the data to your database
  const save = async (pageData: any) => {
    if (edit) {
      const response = await APIService.updatePage(id, {
        ...pageData,
        image: !pageData?.image ? '' : pageData.image,
        _id: undefined,
        created_at: undefined,
        created_by: undefined,
        updated_at: undefined,
        updated_by: undefined,
      });
      if (response.ok) {
        message.success(t('general.updateSuccess'));
      } else {
        message.error(response.data?.message);
      }
    } else {
      for (const key in pageData.slug) {
        if (!pageData.slug[key]) {
          delete pageData.slug[key];
        }
      }
      const response = await APIService.createPage(pageData);
      if (response.ok) {
        message.success(t('general.createSuccess'));
        nav('/dashboard/pages/' + response?.data?._id);
      } else {
        message.error(response.data?.message);
      }
    }
  };
  if (isLoading)
    return (
      <Card className="fadeIn">
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </Card>
    );
  return (
    <Card className="fadeIn">
      <Row justify="space-between" align="middle">
        <Col>
          <Breadcrumb
            items={[
              {
                href: '#',
                onClick: () => nav('/dashboard'),
                title: <HomeOutlined />,
              },
              {
                href: '#',
                onClick: () => nav('/dashboard/pages'),
                title: (
                  <>
                    <BookOutlined />
                    <span>{t('menu.pages')}</span>
                  </>
                ),
              },
              {
                href: '#',
                title: (
                  <>
                    <span>{page?.name[language as 'en' | 'es'] || t('general.new')}</span>
                  </>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Divider />
      <Puck
        key={language}
        iframe={{ enabled: false }}
        config={PuckConfig as Config}
        data={JSON.parse(
          (edit ? page : state.current)?.content[language as 'en' | 'es'] || '{}',
        )}
        viewports={[{ width: 1440, icon: 'Monitor' }]}
        ui={{
          rightSideBarVisible: false,
          viewports: {
            controlsVisible: false,
            current: {
              height: 'auto',
              width: 1440,
            },
            options: [
              {
                icon: 'Monitor',
                width: 1440,
              },
            ],
          },
        }}
        overrides={{
          header: ({ children }) => <div className="wrapper-puck-header">{children}</div>,
          puck: ({ children }) => <div className="puck-container">{children}</div>,
          actionBar: ({ children }) => (
            <div className="custom-action-wrapper">
              <ActionBar label="Actions">
                <ActionBar.Group>{children}</ActionBar.Group>
              </ActionBar>
            </div>
          ),
          headerActions: () => {
            const { appState, dispatch } = usePuck();
            const [pageData, setPageData] = React.useState<any>(state.current);
            useEffect(() => {
              state.current = pageData;
            }, [pageData]);
            useEffect(() => {
              state.current.content[language as 'en' | 'es'] = JSON.stringify(
                appState.data,
              );
              if (page) {
                page.content[language as 'en' | 'es'] = JSON.stringify(appState.data);
              }
            }, [appState]);
            return (
              <>
                <div>
                  <Input
                    style={{ minWidth: 160 }}
                    placeholder={t('general.slug') as string}
                    defaultValue={pageData?.slug[language as 'en' | 'es']}
                    onChange={(e) => {
                      setPageData((d: any) => {
                        return {
                          ...d,
                          slug: {
                            ...d.slug,
                            [language as 'en' | 'es']: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </div>
                <TypeSelector
                  {...({
                    defaultValue: pageData?.type_id,
                    onChange: (e: string) => setPageData({ ...pageData, type_id: e }),
                    placeholder: t('general.type'),
                  } as any)}
                />
                <StatusSelector
                  {...({
                    defaultValue: pageData?.status,
                    onChange: (e: string) => setPageData({ ...pageData, status: e }),
                  } as any)}
                />
                <div className="select-main-image">
                  <ImagePicker
                    showRemove={true}
                    value={pageData?.image}
                    onChange={(e) => setPageData({ ...pageData, image: e })}
                  />
                </div>
                <div>
                  <Button
                    type="dashed"
                    onClick={() =>
                      dispatch({
                        type: 'setData',
                        data:
                          language === 'en'
                            ? JSON.parse(page?.content.es || '{}')
                            : JSON.parse(page?.content.en || '{}'),
                      })
                    }
                  >
                    {t('puck.copyLanguage')}
                  </Button>
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={() =>
                      save({
                        ...pageData,
                        content: {
                          ...pageData?.content,
                          [language]: JSON.stringify(appState.data),
                        },
                        name: {
                          ...pageData?.name,
                          [language as 'en' | 'es']: appState.data?.root?.props?.title,
                        },
                      })
                    }
                    icon={<SaveOutlined />}
                  >
                    {edit ? t('general.save') : t('general.create')}
                  </Button>
                </div>
              </>
            );
          },
        }}
      />
    </Card>
  );
};

export default PageEditView;
